@import "../variables.modules";

footer {
  color: $dark;
}

.dark {
  background-color: $dark;
  color: $white;
  transition: all 400ms;

  a, i, li, p {
    color: $white;
    transition: color 400ms;
  }

  a:visited {
    color: $white;
  }

  footer {
    color: $white;
  }  

  section {
      color: $white;
      
  }
  
  header {
    background-color:$darkerDark;
  }
}

.light {
  background-color: $white;
  color: $dark;
  transition: all 400ms;

  section {
    color: $dark;
  }

  header {
    background-color:$darkerWhite;
  }
}