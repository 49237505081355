$purple: #8D53FF;
$pink: #CA6BE6;
$white: #f8f8f8;
$darkerWhite: #e3e3e3;
$dark: #202124;
$darkerDark: #171717;
$red: #FF6057;
$yellow: #FFBD2E;
$green: #27C93F;
$lightgray: #c9c9c9;
$blue: #2e5fa7;
$darkBlue: #162d50;

$transition: all 250ms ease;

.shadowed {
  box-shadow: 0 0.5rem 0 0rem rgba(0,0,0,0.4);
}